<script>
// import LazyHydrate from 'vue-lazy-hydration'
export default {
	// components: { LazyHydrate },
	data() {
		return {
			items: [],
		}
	},
	/*mobileReload(context, to, from) {
		return true
	},*/
	async resolveData(context, to, from, next) {
		const { ssr } = context

		try {
			if (ssr) {
				let items = await ssr.loadViewData('home')
				ssr.shouldCache = true
				return { items }
			} else {
				const { apis, store } = context
				const { data } = await apis.shopApi.get('/home-data', {
					loading: (v) => store.set('shop/routeLoading', v),
				})
				return { items: data.items }
			}
		} catch (err) {
			next(err)
		}
	},
	trackPageView: true,
}
</script>

<template>
	<DynamicPageItems :items="items" css-vars-key="home" />
</template>

<style></style>

